<template>
    <r-e-dialog title="账单抵扣" :visible.sync="dialogVisible" show-footer top="5vh"
                width="600px"
                @click-submit="handleSubmitPublish" @click-cancel="handleCancel" @close="handleCancel">
        <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" >
            <div class="flex">
                <el-form-item label="账单信息">
                    <el-input v-model="formPublish.name" disabled />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="账单金额">
                    <el-input v-model="formPublish.amount" disabled />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="已付金额">
                    <el-input v-model="formPublish.amountPaid" disabled />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="未付金额">
                    <el-input v-model="formPublish.unpaidAmount" disabled />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="余额抵扣">
                    <el-input v-model="formPublish.balanceDeduction" disabled />
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="余额">
                    <el-input v-model="formPublish.accountBalance" disabled/>
                </el-form-item>
            </div>
        </el-form>
    </r-e-dialog>
</template>

<script>
import {getContractByUuidOrLeasorUuid} from "@/api/contract";
import {getBillDeductionAPI,balanceDeductionAPI} from "@/api/bill";
import {MessageSuccess, MessageError, MessageWarning} from "@custom/message";

export default {
    name: "dialog-add-bill",
    data() {
        return {
            dialogVisible: false,
            formPublish: {
                name: null, //账单名称 
                amount: null, //账单金额
                amountPaid: null, // 已付金额
                unpaidAmount: null,//未付金额
                balanceDeduction: null,//	余额抵扣
                accountBalance: null,//账户余额
            },
            billUuid:null, // 账单uuid 
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        }
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        },
        zUuid: {
            type: String,
            default: null,
        }
    },
    async created() {
    },
    methods: {
        //打开添加房租账单弹框方法
        async openDialog(data) {
            let that = this;
            let { uuid } = data;
            that.billUuid = uuid
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
           await getBillDeductionAPI({uuid:that.billUuid}).then(res => {
            let { info } = res;
            that.formPublish = info;
            }).finally(() => loading.close());
            that.dialogVisible = true;
        },

        //确认事件
        async handleSubmitPublish() {
            let that = this;
            console.log('this.od',that.billUuid);
            const loadingOptions = that.loadingOptions;
            const loading = that.$loading({...loadingOptions});
            await balanceDeductionAPI({uuid:that.billUuid}).then(res => {
            console.log('res',res);
            MessageSuccess('账单抵扣成功');
                //调用取消关闭弹框
                that.handleCancel();
            }).finally(() => loading.close());
        },

        // 弹框取消事件
        handleCancel() {
            this.dialogVisible = false;
            //调用父组件方法 刷新未支付房租账单列表
            this.$emit('clickCancel');
        },
    }
}
</script>
<style lang="scss" scoped>
.el-select {
    width: 100%;
}

.flex {
    display: flex;

    /deep/ .el-form-item {
        flex: 1;
        white-space: nowrap;
    }

    /deep/ .amount {
        .el-input {
            width: 42%;
        }
    }

    /deep/ .tag-group {
        white-space: normal;

        .el-tag {
            margin: 0 VW(5px) VH(5px) 0;
            cursor: pointer;
        }
    }

    /deep/ .samplePhotos {
        white-space: normal;
    }
}
</style>
<style>
.v-modal {
    z-index: 2000 !important;
}
</style>
